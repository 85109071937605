export const SET_TOUR_TYPE_BACKEND = 'SET_TOUR_TYPE_BACKEND';
export const SET_TOUR_TYPE_HOW_TO_HUB = 'SET_TOUR_TYPE_HOW_TO_HUB';
export const FINISH_TOUR = 'FINISH_TOUR';
export const COMPONENT_RENDERED = 'COMPONENT_RENDERED';
export const RESET_RENDERED_STATE = 'RESET_RENDERED_STATE';
export const DELETE_ADDED_PAGE_MODULE = 'DELETE_ADDED_PAGE_MODULE';
export const SET_LAST_PAGE_MODULE_UID = 'SET_LAST_PAGE_MODULE_UID';
export const SET_TOUR_IS_OPEN = 'SET_TOUR_IS_OPEN';

// possible tourTypes
export const NEW_USER = 'newUser';
export const RETURNING_USER = 'returningUser';
export const MULTI_LOCATION_USER = 'multiLocationUser';
