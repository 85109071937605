import React from 'react';

const ProductPage = React.lazy(() => import('./Product/productPage'));
const CreateProductPage = React.lazy(() => import('./Product/createProductPage'));
const EditProductPage = React.lazy(() => import('./Product/editProductPage'));
const ProductGroupsPage = React.lazy(() => import('./ProductGroups/productGroupsPage'));
const RegionsPage = React.lazy(() => import('./Regions/regionsPage'));
const TiersPage = React.lazy(() => import('./Tiers/tiersPage'));
const RetailersPage = React.lazy(() => import('./Retailers/retailersPage'));

const BrandsPage = React.lazy(() => import('./Library/brandsPage'));
const BrandContentPage = React.lazy(() => import('./BrandContent/brandContentPage'));
const CreateBrandContentPage = React.lazy(() => import('./BrandContent/createBrandContentPage'));
const EditBrandContentPage = React.lazy(() => import('./BrandContent/editBrandContentPage'));
const EditThemePage = React.lazy(() => import('./Themes/editThemePage'));

const AdminsPage = React.lazy(() => import('./Admins/adminsPage'));
const ManageWelcomeScreen = React.lazy(() => import('./ManageWelcomeScreen/manageWelcomeScreen'));
const WelcomeScreenPage = React.lazy(() => import('./ManageWelcomeScreen/WelcomeScreenPage'));

const StylistPage = React.lazy(() => import('./Stylists/StylistPage'));
const ApproveStylistPage = React.lazy(() => import('./Stylists/ApproveStylistPage'));
const MyLocationsPage = React.lazy(() => import('./Locations/LocationsPage/LocationsPage'));
const PreviewLocationPage = React.lazy(() => import('./Locations/LocationNavbar'));
const LandingPage = React.lazy(() => import('./LandingPage/landingPage'));
const LearningCenter = React.lazy(() => import('./LearningCenter/LearningCenter'));
const LearningCentersList = React.lazy(() => import('./LearningCenter/LearningCentersList'));
const Terms = React.lazy(() => import('./Terms/Terms'));
const LooksPage = React.lazy(() => import('./Looks/pages/LooksPage'));
const EditLookPage = React.lazy(() => import('./Looks/pages/EditLookPage/EditLookPage'));
const CreateSupportLookPage = React.lazy(() =>
  import('./Looks/pages/EditLookPage/CreateSupportLook'),
);
const AddProducts = React.lazy(() => import('./Looks/pages/AddProductsPage/AddProductsPage'));
const TemplateLookPage = React.lazy(() => import('./Looks/pages/Template/TemplatesPage'));

const retailerDetailsPage = React.lazy(() =>
  import('./Retailers/retailerDetails/retailerDetailsPage'),
);
const ForgetPassword = React.lazy(() => import('./Login/ForgetPassword'));
const QRCodePage = React.lazy(() => import('./QRCodePage/QRCodePage'));
const FeaturedArtistPage = React.lazy(() => import('./FeaturedArtist/FeaturedArtistPage'));

const ProductCatalogPage = React.lazy(() => import('./ProductCatalog'));
const FavouriteProductsPage = React.lazy(() => import('./FavouriteProducts'));
const ShareableLinksPage = React.lazy(() => import('./ShareableLinks'));

export {
  ProductPage,
  CreateProductPage,
  EditProductPage,
  RetailersPage,
  RegionsPage,
  TiersPage,
  ProductGroupsPage,
  BrandsPage,
  BrandContentPage,
  CreateBrandContentPage,
  EditBrandContentPage,
  EditThemePage,
  AdminsPage,
  StylistPage,
  ApproveStylistPage,
  MyLocationsPage,
  PreviewLocationPage,
  ManageWelcomeScreen,
  LandingPage,
  LearningCenter,
  Terms,
  retailerDetailsPage,
  ForgetPassword,
  QRCodePage,
  LearningCentersList,
  WelcomeScreenPage,
  LooksPage,
  EditLookPage,
  CreateSupportLookPage,
  AddProducts,
  FeaturedArtistPage,
  TemplateLookPage,
  ProductCatalogPage,
  FavouriteProductsPage,
  ShareableLinksPage,
};
