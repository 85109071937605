import {
  LOCATION_SELECT,
  UPDATE_SELECTED_LOCATION,
  UPDATE_HEADER,
  SET_SAVING,
  GET_LOCATION_GROUP,
  GET_LOCATION_GROUP_SUCCESS,
  DELETE_LOCATION_GROUP,
  DELETE_LOCATION_GROUP_SUCCESS,
  UPDATE_LOCATION_GROUP_SUCCESS,
  UPDATE_LOCATION_PAGE_ELEMENT_SUCCESS,
  UPDATE_LOCATION_PAGE_ELEMENT,
  ADD_LOCATION_PAGE_ELEMENT,
  ADD_LOCATION_PAGE_ELEMENT_SUCCESS,
  DELETE_LOCATION_PAGE_ELEMENT,
  DELETE_LOCATION_PAGE_ELEMENT_SUCCESS,
  CREATE_LOCATION_GROUP,
  CREATE_LOCATION_GROUP_SUCCESS,
  UPDATE_LOCATION_GROUP,
  PUBLISH_LOCATION_PAGE_ELEMENTS,
  PUBLISH_LOCATION_PAGE_ELEMENTS_SUCCESS,
  UPDATE_LOCATION_PAGE_ELEMENT_POSITION,
  UPDATE_LOCATION_PAGE_ELEMENT_POSITION_SUCCESS,
  DELETE_TOUR_PAGE_MODULE,
} from './constants';
import { RELOAD_LOCATION_CONTENT } from '../looks/constants';

export function selectLocation(location) {
  return {
    type: LOCATION_SELECT,
    payload: { location },
  };
}

export function updateLocation() {
  return {
    type: UPDATE_SELECTED_LOCATION,
    payload: {},
  };
}

export function updateHeader(location) {
  return {
    type: UPDATE_HEADER,
    payload: { location },
  };
}

export function setSavingFlag(value) {
  return {
    type: SET_SAVING,
    payload: value,
  };
}

export function getLocationGroup() {
  return {
    type: GET_LOCATION_GROUP,
  };
}

export function createLocationGroup(values) {
  return {
    type: CREATE_LOCATION_GROUP,
    payload: { values },
  };
}

export function createLocationGroupSuccess(locationGroup) {
  return {
    type: CREATE_LOCATION_GROUP_SUCCESS,
    payload: { locationGroup },
  };
}

export function updateLocationGroup(groupId, values) {
  return {
    type: UPDATE_LOCATION_GROUP,
    payload: { groupId, values },
  };
}

export function deleteLocationGroup(groupId) {
  return {
    type: DELETE_LOCATION_GROUP,
    payload: groupId,
  };
}

export function deleteLocationGroupSuccess() {
  return {
    type: DELETE_LOCATION_GROUP_SUCCESS,
  };
}

export function getLocationGroupSuccess(value) {
  return {
    type: GET_LOCATION_GROUP_SUCCESS,
    payload: value,
  };
}

export function updateLocationGroupSuccess(locationGroup) {
  return {
    type: UPDATE_LOCATION_GROUP_SUCCESS,
    payload: { locationGroup },
  };
}

export function addLocationPageElement(payload) {
  return {
    type: ADD_LOCATION_PAGE_ELEMENT,
    payload,
  };
}

export function addLocationPageElementSuccess(locationPages) {
  return {
    type: ADD_LOCATION_PAGE_ELEMENT_SUCCESS,
    payload: locationPages,
  };
}

export function updateLocationPageElement(payload) {
  return {
    type: UPDATE_LOCATION_PAGE_ELEMENT,
    payload,
  };
}

export function updateLocationPageElementSuccess(locationPages) {
  return {
    type: UPDATE_LOCATION_PAGE_ELEMENT_SUCCESS,
    payload: locationPages,
  };
}

export function updateLocationPageElementPosition(payload) {
  return {
    type: UPDATE_LOCATION_PAGE_ELEMENT_POSITION,
    payload,
  };
}

export function updateLocationPageElementPositionSuccess(locationPages) {
  return {
    type: UPDATE_LOCATION_PAGE_ELEMENT_POSITION_SUCCESS,
    payload: locationPages,
  };
}

export function deleteLocationPageElement(payload) {
  return {
    type: DELETE_LOCATION_PAGE_ELEMENT,
    payload,
  };
}

export function deleteLocationPageElementSuccess(locationPages) {
  return {
    type: DELETE_LOCATION_PAGE_ELEMENT_SUCCESS,
    payload: locationPages,
  };
}

export function publishLocationPageElements() {
  return {
    type: PUBLISH_LOCATION_PAGE_ELEMENTS,
  };
}

export function publishLocationPageElementsSuccess() {
  return {
    type: PUBLISH_LOCATION_PAGE_ELEMENTS_SUCCESS,
  };
}

export function deleteTourPageModule() {
  return {
    type: DELETE_TOUR_PAGE_MODULE,
  };
}

export function reloadLocationContent(locationUid, locationVanityUrl = null) {
  return {
    type: RELOAD_LOCATION_CONTENT,
    payload: { locationUid, locationVanityUrl },
  };
}
