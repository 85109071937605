import React, { useEffect, useReducer, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { Form, Modal, Table, Wrapper } from '../../components';
import ReportService from '../../api/services/ReportService';
import { ReactComponent as SaveIcon } from '../../assets/svg/downloadFile.svg';

const ReportsPage = ({ showReportsHistory = true }) => {
  const { t: prefixT } = useTranslation('ownerPortal', { keyPrefix: 'reports' });

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case 'set_reports':
        return { ...state, reports: payload, reportsLoaded: true };
      case 'set_reports_requests':
        return { ...state, reportsHistory: payload, reportsRequestsLoaded: true };
      case 'clear':
        return {
          ...state,
          ...initialState,
        };
      case 'select_report':
        return { ...state, selectedReport: payload };
      case 'deselect_report':
        return { ...state, selectedReport: null };
      default:
        return state;
    }
  };

  const initialState = {
    reports: [],
    reportsLoaded: false,
    reportsHistory: [],
    reportsRequestsLoaded: false,
    selectedReport: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const formatTime = timeStr => {
    const date = new Date(timeStr);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const formatIdentifierToTitle = input => {
    let formattedString = input?.replace(/_/g, ' ').toLowerCase();
    formattedString = formattedString?.replace(/\b\w/g, letter => letter.toUpperCase());
    return formattedString;
  };

  const mapMinutesToString = calculationTime => {
    const seconds = Math.round(calculationTime * 60);

    if (seconds < 60) {
      return 'less than a minute';
    }

    const minutes = Math.round(calculationTime);
    return `${minutes} minutes`;
  };

  const fetchReports = () => {
    ReportService.getAvailableReports().then(response => {
      const updatedReports = response.map(report => ({
        ...report,
        last_request: report.last_request
          ? `last requested ${formatTime(report.last_request)}`
          : prefixT('reportNotRunning'),
        report_name_formated: formatIdentifierToTitle(report.name),
      }));

      dispatch({ type: 'set_reports', payload: updatedReports });
    });
  };

  const fetchRequests = () => {
    ReportService.getReportsRequests().then(response => {
      const updatedReports = response.map(report => ({
        ...report,
        completed_at: report.completed_at ? formatTime(report.completed_at) : null,
        requested_at: report.requested_at ? formatTime(report.requested_at) : null,
        report_name_formated: formatIdentifierToTitle(report.report_title),
        calculation_time: report.calculation_time
          ? mapMinutesToString(report.calculation_time)
          : null,
      }));
      dispatch({ type: 'set_reports_requests', payload: updatedReports });
    });
  };

  const [shouldPoll, setShouldPoll] = useState(false);

  useEffect(() => {
    if (shouldPoll) {
      const intervalId = setInterval(() => {
        fetchRequests();
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [shouldPoll]);

  useEffect(() => {
    fetchReports();
    fetchRequests();
  }, []);

  useEffect(() => {
    const hasIncompleteReports = state.reportsHistory.some(report => !report.completed_at);
    setShouldPoll(hasIncompleteReports); // Update polling state
  }, [state.reportsHistory]);

  const RequestReportModal = () => {
    const defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
    const [startDate, setStartDate] = useState(defaultStartDate.toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    return (
      <Modal
        header={{
          title: prefixT('modalTitle'),
          subTitle: prefixT('modalSubtitle'),
        }}
        open={state.selectedReport}
        onClose={() => dispatch({ type: 'deselect_report' })}
        wide
      >
        <Modal.ClosableBody>
          {({ closePopup }) => (
            <Form
              initialValues={{}}
              onSubmit={async () => {
                await sendRequestButtonHandler(state.selectedReport.name, startDate, endDate);
                closePopup();
              }}
            >
              <Row>
                <div>{prefixT('selectedReport')}</div>
                <div>{state.selectedReport.report_name_formated}</div>
              </Row>
              <Row>
                <div>{prefixT('reportDescription')}</div>
                <div>{state.selectedReport.description}</div>
              </Row>

              {state.selectedReport.data_range_required === true && (
                <>
                  <Row>
                    <div>{prefixT('startData')}</div>
                    <div>
                      <input
                        type="date"
                        value={startDate}
                        onChange={event => setStartDate(event.target.value)}
                        style={{ maxWidth: '200px' }}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div>{prefixT('endDate')}</div>
                    <div>
                      <input
                        type="date"
                        value={endDate}
                        onChange={event => setEndDate(event.target.value)}
                        style={{ maxWidth: '200px' }}
                      />
                    </div>
                  </Row>
                </>
              )}
              <br />
              <Wrapper d-flex justify-content-end>
                <Form.Submit>{prefixT('submitButton')}</Form.Submit>
              </Wrapper>
            </Form>
          )}
        </Modal.ClosableBody>
      </Modal>
    );
  };

  const sendRequestButtonHandler = (reportName, startDate, endDate) => {
    ReportService.sendReportRequest(reportName, startDate, endDate)
      .then(() => {
        toastr.success(prefixT('requestSendSuccess'));
        dispatch({ type: 'clear' });
        fetchReports();
        fetchRequests();
      })
      .catch(() => {
        toastr.error(prefixT('requestSendFailure'));
      });
  };

  const reports_table_columns = [
    {
      Header: prefixT('t1Name'),
      accessor: 'report_name_formated',
    },
    {
      Header: prefixT('t1Description'),
      accessor: 'description',
      sortable: false,
    },
    {
      Header: prefixT('t1RunningRequest'),
      accessor: 'last_request',
      sortable: false,
    },
  ];

  const status_table_columns = [
    {
      Header: prefixT('rowTitle'),
      accessor: 'report_name_formated',
      sortable: false,
    },
    {
      Header: 'Request time',
      accessor: 'requested_at',
      sortable: false,
    },
    {
      Header: prefixT('rowTime'),
      accessor: 'calculation_time',
      sortable: false,
    },
    {
      Header: 'download',
      renderCell: cell => {
        const { completed_at, uid } = cell.row.original;

        if (!completed_at) {
          return (
            <div
              className="d-flex align-items-center justify-content-start"
              style={{ height: '100%' }}
            >
              <span
                className="spinner-border text-primary"
                role="status"
                aria-hidden="true"
                style={{
                  width: '1.5rem',
                  height: '1.5rem',
                }}
              />
            </div>
          );
        }

        return (
          <div
            className="d-flex align-items-center justify-content-start"
            style={{
              height: '100%',
            }}
          >
            <SaveIcon
              role="button"
              onClick={async () => {
                try {
                  const response = await ReportService.getDownloadLink(uid);
                  if (!response || !response.url) {
                    throw new Error('Invalid download URL');
                  }
                  const { url } = response;
                  window.open(url, '_self');
                } catch (error) {
                  toastr.error(prefixT('downloadFailure'));
                }
              }}
              title="Download"
              style={{
                width: '1.75rem',
                height: '1.75rem',
                cursor: 'pointer',
                fill: '#0d6efd',
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  const Row = ({ children }) => (
    <div style={{ display: 'flex', width: '100%', padding: '0 5%' }}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          style: {
            flex: index === 0 ? '1' : '2', // Apply flex ratio
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            ...child.props.style,
          },
        }),
      )}
    </div>
  );

  function DateInput({ date, setDate }) {
    return (
      <input
        type="date"
        value={date}
        onChange={event => setDate(event.target.text)}
        style={{ maxWidth: '200px' }}
      />
    );
  }

  Row.propTypes = {
    children: PropTypes.node.isRequired,
  };
  DateInput.propTypes = {
    date: PropTypes.string.isRequired,
    setDate: PropTypes.func.isRequired,
  };

  ReportsPage.propTypes = {
    showReportsHistory: PropTypes.bool,
  };

  return (
    <>
      <Container className="mt-5">
        <Table data={state.reports} loading={!state.reportsLoaded} columns={reports_table_columns}>
          <Table.Header title={prefixT('availableReports')}>
            <RequestReportModal />
          </Table.Header>
          <Table.Content
            deletable={false}
            onRowClick={selectedRow => {
              dispatch({
                type: 'select_report',
                payload: state.reports.find(report => report.id === selectedRow.id),
              });
            }}
          />
        </Table>

        {showReportsHistory && (
          <Table
            data={state.reportsHistory}
            loading={!state.reportsRequestsLoaded}
            columns={status_table_columns}
          >
            <Table.Header title={prefixT('deliveredReports')} />
            <Table.Content deletable={false} />
          </Table>
        )}
      </Container>
    </>
  );
};

export default ReportsPage;
