import * as types from './constants';

export const setTourTypeActionBackend = tourType => ({
  type: types.SET_TOUR_TYPE_BACKEND,
  payload: {
    tourType,
  },
});

export const setTourTypeActionHowToHub = tourType => ({
  type: types.SET_TOUR_TYPE_HOW_TO_HUB,
  payload: {
    tourType,
  },
});

export const finishTourAction = () => ({
  type: types.FINISH_TOUR,
});

export const deleteAddedPageModule = () => ({
  type: types.DELETE_ADDED_PAGE_MODULE,
});

export const setLastPageModuleUid = uid => ({
  type: types.SET_LAST_PAGE_MODULE_UID,
  payload: { uid },
});

export const notifyAboutRender = name => ({
  type: types.COMPONENT_RENDERED,
  payload: {
    name,
  },
});

export const resetRenderedState = () => ({
  type: types.RESET_RENDERED_STATE,
});

export const setTourIsOpen = isOpen => ({
  type: types.SET_TOUR_IS_OPEN,
  payload: { isOpen },
});
