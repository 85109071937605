import React from 'react';
import * as route from 'constants/routes';
import StorefrontIcon from '@material-ui/icons/Storefront';
import i18n from 'i18n';
import { SUPER_ADMIN, ADMIN, BRAND_ADMIN, OWNER, MANAGER } from 'constants/roles';
import { hasLocationsGroupSelector } from 'redux/locations/selectors';
import {
  isEmbeddableWidgets,
  isLandingPage,
  isShareableLinks,
  isOwnerGroupPage,
  isArtistsTab,
} from '../../utils/featureFlags.ts';

export const menuItems = [
  {
    id: '1',
    label: i18n.t('ownerPortal:menu.myWebShoppe'),
    icon: 'fa-shopping-cart',
    renderIcon: () => (
      <div className="fe">
        <StorefrontIcon />
      </div>
    ),
    link: route.MY_LOCATIONS,
    roles: [OWNER, MANAGER],
  },
  {
    id: '2',
    label: i18n.t('ownerPortal:menu.looks'),
    icon: 'fe-layout',
    roles: [OWNER, MANAGER],
    preview: ['aveda'],
    link: route.LOOKS,
    tourClassName: 'tour-looks',
  },
  {
    id: '3',
    label: i18n.t('ownerPortal:menu.products'),
    icon: 'fe-star',
    roles: [OWNER, MANAGER],
    preview: ['aveda'],
    link: route.FAVOURITE_PRODUCTS,
    tourClassName: 'tour-favorite-products',
  },
  isArtistsTab()
    ? {
        id: '4',
        label: i18n.t('ownerPortal:menu.manageArtists'),
        icon: 'fe-users',
        link: route.MANAGE_STYLISTS,
        roles: [OWNER, MANAGER],
      }
    : null,
  isShareableLinks()
    ? {
        id: '5',
        label: i18n.t('ownerPortal:menu.sherableLinks'),
        icon: 'fe-link',
        roles: [OWNER, MANAGER],
        preview: ['aveda'],
        link: route.SHAREABLE_LINKS,
      }
    : null,
  {
    id: '6',
    label: i18n.t('ownerPortal:menu.dashboard'),
    adminToolLabel: i18n.t('menu:dashboard'),
    icon: 'fe-bar-chart-2',
    link: route.DASHBOARD,
    tourClassName: 'tour-activity-tracker',
  },
  {
    id: '7',
    label: i18n.t('ownerPortal:menu.howToHub'),
    icon: 'fe-anchor',
    link: route.LANDING_PAGE,
    roles: [OWNER, MANAGER],
  },
  {
    id: '2',
    label: 'Reports',
    icon: 'fe-file-text',
    link: route.GENERATE_REPORTS,
    roles: [ADMIN, BRAND_ADMIN],
  },
  {
    id: '8',
    label: i18n.t('menu:products'),
    icon: 'fe-credit-card',
    link: route.PRODUCTS,
    roles: [ADMIN, BRAND_ADMIN],
    items: [
      {
        id: '8.1',
        label: i18n.t('menu:products'),
        link: route.PRODUCTS,
      },
      {
        id: '8.2',
        label: i18n.t('menu:product_groups'),
        link: route.PRODUCTS_GROUPS,
        preview: ['mac'],
      },
      {
        id: '8.3',
        label: i18n.t('menu:excluded_categories'),
        link: route.PRODUCT_CATALOG,
        roles: [ADMIN, BRAND_ADMIN],
      },
    ],
  },
  {
    id: '9',
    label: i18n.t('menu:sellers'),
    icon: 'fe-users',
    link: route.RETAILERS,
    roles: [ADMIN, BRAND_ADMIN],
    items: [
      {
        id: '7.1',
        label: i18n.t('menu:sellersList'),
        link: route.RETAILERS,
      },
    ],
  },
  {
    id: '10',
    label: i18n.t('menu:regions'),
    icon: 'fe-map-pin',
    link: route.REGIONS,
    roles: [SUPER_ADMIN, BRAND_ADMIN],
  },
  {
    id: '11',
    label: i18n.t('menu:brands'),
    icon: 'fe-git-branch',
    link: route.BRAND_CONTENT,
    superAdminLink: route.BRAND,
    roles: [ADMIN, BRAND_ADMIN, SUPER_ADMIN],
    items: [
      {
        id: '11.1',
        label: i18n.t('menu:brands'),
        link: route.BRAND,
        roles: [SUPER_ADMIN],
      },
      {
        id: '11.2',
        label: i18n.t('menu:brandLooks'),
        link: route.LOOK_TEMPLATE,
        roles: [ADMIN, BRAND_ADMIN],
      },
      {
        id: '11.3',
        label: i18n.t('menu:brandContent'),
        link: route.BRAND_CONTENT,
        roles: [ADMIN, BRAND_ADMIN],
      },
      {
        id: '11.4',
        label: i18n.t('menu:themes'),
        link: route.EDIT_THEME,
        roles: [ADMIN, BRAND_ADMIN],
      },
      {
        id: '11.5',
        label: i18n.t('menu:welcomeScreen'),
        link: route.WELCOME_SCREENS,
        roles: [ADMIN, BRAND_ADMIN],
      },
      {
        id: '11.6',
        label: i18n.t('menu:learningCenter'),
        link: route.LEARNING_CENTERS,
        roles: [ADMIN, BRAND_ADMIN],
      },
      isLandingPage()
        ? {
            id: '12',
            label: i18n.t('menu:featuredArtists'),
            link: route.FEATURED_ARTIST,
            roles: [ADMIN, BRAND_ADMIN],
          }
        : null,
      isEmbeddableWidgets()
        ? {
            id: '14',
            label: i18n.t('menu:embeddableWidgets'),
            link: route.WIDGETS,
            roles: [ADMIN, BRAND_ADMIN],
          }
        : null,
    ],
  },
  {
    id: '15',
    label: i18n.t('menu:admins'),
    icon: 'fe-users',
    link: route.ADMINS,
    roles: [SUPER_ADMIN],
  },
  {
    id: '16',
    label: i18n.t('menu:locations'),
    icon: 'fe-map-pin',
    roles: [ADMIN, BRAND_ADMIN],
    preview: ['aveda'],
    link: route.MY_LOCATIONS,
  },
  isOwnerGroupPage()
    ? {
        id: '17',
        label: i18n.t('menu:ownerGroupPage'),
        icon: 'fe-grid',
        link: route.OWNER_GROUP_PAGE,
        roles: [MANAGER],
        guard: hasLocationsGroupSelector,
      }
    : null,
  {
    id: '18',
    label: 'Reports',
    icon: 'fe-file-text',
    link: route.GENERATE_REPORTS,
    roles: [SUPER_ADMIN],
  },
];
