import CoreService from './CoreService';

class LooksService extends CoreService {
  /* eslint-disable no-useless-constructor */
  constructor(endpoint) {
    super(endpoint);
  }

  fetchUserLooks() {
    return this.api.get('/v1/myStores');
  }

  fetchSingleLook(uid) {
    return this.api.get(`/v1/myStores/${uid}`);
  }

  fetchTemplateLooks() {
    return this.api.get(`/v3/store_templates`);
  }

  createLook(content) {
    return this.api.post('/v2/store', content);
  }

  createSupportLook(content) {
    return this.api.post('/v5/store/create_support_store', content);
  }

  createLookFromTemplateRandomOwner(locationUid, templateId) {
    return this.api.post('/v5/store/create_store_from_template', {
      location: locationUid,
      store_template: templateId,
    });
  }

  patchLook(uid, content) {
    return this.api.patch(`/v2/store/${uid}`, content);
  }

  changeLookStatus(lookId, data) {
    return this.api.put(`/v2/store/${lookId}/status`, data);
  }

  updateIsPrivate(lookId, is_private) {
    return this.api.post(`/v2/store/${lookId}/private`, { is_private });
  }

  transferOwnership(lookId, targetUserEmail) {
    return this.api.post(`/v5/store/${lookId}/transfer_ownership`, {
      target_retailer_email: targetUserEmail,
    });
  }

  fetchLookProducts(lookId) {
    return this.api.get(`/v2/store/${lookId}/products`);
  }

  deleteLook(lookId) {
    return this.api.delete(`/v2/store/${lookId}`);
  }

  fetchAvailableProducts(regionUid) {
    return this.api.get(`/v3/retailer_info/products?region=${regionUid}`);
  }

  addProductsToLook(data) {
    return this.api.post('/v2/products', data);
  }

  patchProduct(lookId, parent_product, data) {
    return this.api.patch(`/v2/products/${parent_product}`, {
      ...data,
      store_id: lookId,
      parent_product,
    });
  }

  deleteProduct(lookId, productId) {
    return this.api.delete(`/v2/store/${lookId}/products/${productId}`);
  }

  searchProducts({ regionUid, productName }) {
    return this.api.get(`/v3/retailer_info/products?name=${productName}&region=${regionUid}`);
  }

  getStats(lookId) {
    return this.api.get(`/v3/entry/social_views?entry_id=${lookId}`);
  }

  genereateStoreLinks(shortId, withToken = false, duration = 1) {
    return this.api.post(`/v2/store/${shortId}/generatelink`, {
      with_token: withToken,
      duration,
    });
  }

  createSingleLook({ parent_product, primary_sku, sub_skus }) {
    return this.api.post('/v2/products/generate_link', { parent_product, primary_sku, sub_skus });
  }

  createSingleLookAdminTool({ parent_product, primary_sku, sub_skus, location_uid }) {
    return this.api.post('/v2/products/generate_link', {
      parent_product,
      primary_sku,
      sub_skus,
      location: location_uid,
    });
  }

  removeCoverPhoto(cover_photo_id) {
    return this.api.delete(`/v3/store_images/${cover_photo_id}`);
  }

  fetchCoverPhotos(lookId) {
    return this.api.get(`/v3/store_images/single/${lookId}`);
  }

  addCoverPhotos(store_id, cover_photo_id, sort_order) {
    return this.api.post(`/v3/store_images`, { store_id, cover_photo_id, sort_order });
  }
}

const instance = new LooksService('');

export default instance;
