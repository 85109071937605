import React from 'react';

import { useTranslation } from 'react-i18next';
import LooksService from 'api/services/LooksService';
import { useSelector } from 'react-redux';
import { singleLookSelector } from 'redux/looks/selectors';
import { Button } from 'components';

const PreviewButton = React.memo(() => {
  const { t } = useTranslation('app');
  const {
    data: { short_id, is_draft, is_private, share_url },
  } = useSelector(singleLookSelector);

  const link = `${share_url}?preview=true`;

  const handleLookPreview = () => {
    const withToken = is_draft || is_private;

    if (is_private || is_draft) {
      LooksService.genereateStoreLinks(short_id, withToken)
        .then(response => {
          const {
            owner: { geo_token },
          } = response;
          window.open(`${link}&token=${geo_token}`, '_blank').focus();
        })
        .catch(() => {
          window.open(link, '_blank').focus();
        });
    } else {
      window.open(link, '_blank').focus();
    }
  };

  return <Button onClick={handleLookPreview}>{t('preview')}</Button>;
});

PreviewButton.propTypes = {};
export { PreviewButton };
