import produce from 'immer';
import AuthService from 'api/services/AuthService';
import * as types from './constants';
import EntityService from '../../api/services/EntityService';

export const initialState = {
  tourType: '',
  isFromHowToHub: false,
  didComponentRender: {},
  createdPageModuleUID: '',
  isTourOpen: false,
};

const tourReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case types.SET_TOUR_IS_OPEN:
        draft.isTourOpen = payload.isOpen;
        break;
      case types.SET_TOUR_TYPE_BACKEND:
        draft.tourType = payload.tourType;
        draft.isFromHowToHub = false;
        break;

      case types.SET_TOUR_TYPE_HOW_TO_HUB:
        draft.tourType = payload.tourType;
        draft.isFromHowToHub = true;
        break;

      case types.FINISH_TOUR:
        draft.tourType = '';

        if (!draft.isFromHowToHub) {
          AuthService.completeTutorial();
        }
        break;

      case types.SET_LAST_PAGE_MODULE_UID:
        draft.createdPageModuleUID = payload.uid;
        break;

      case types.DELETE_ADDED_PAGE_MODULE: {
        if (state.createdPageModuleUID === '') return;
        EntityService.deleteLocationPageModule(state.createdPageModuleUID);
        break;
      }

      case types.COMPONENT_RENDERED:
        draft.didComponentRender = { ...draft.didComponentRender, [payload.name]: true };
        break;

      case types.RESET_RENDERED_STATE:
        draft.didComponentRender = {};
        break;

      default:
        break;
    }
  });

export default tourReducer;
