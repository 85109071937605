import moment from 'moment';
import { createSelector } from 'reselect';
import entities from '../../constants/entities';

const entitySelectors = Object.keys(entities).reduce(
  (o, entity) => ({
    ...o,
    [`${entity}Selector`]: state => state.entities[`${entity}`].data,
    [`${entity}FetchFlagSelector`]: state => state.entities[`${entity}`].fetched,
  }),
  {},
);

export const qrCodesSelector = state => state.entities.qr_code.data;

export const lastInventorySynchroSelector = state => {
  const { region } = state.auth.user;
  const regions = state.entities.region.data;
  const userRegion = regions.find(item => item.name === region);
  return moment(userRegion?.last_inventory_synchro).format('DD.MM.YYYY hh:mm') || '';
};

export const lastLocationApiSynchroSelector = state => {
  const { region } = state.auth.user;
  const regions = state.entities.region.data;
  const userRegion = regions.find(item => item.name === region);
  return moment(userRegion?.last_location_api_synchro).format('DD.MM.YYYY hh:mm') || '';
};

export const getEntityByUid = (entity, uid) => state => {
  if (state.entities[entity]?.data?.results) {
    return state.entities[entity].data.results.find(item => item.uid === uid);
  }

  return state.entities[entity]?.data?.find(item => item.uid === uid);
};

const availableStoresSelector = state => state.entities.available_stores.data;
const storeTemplatesSelector = state => state.entities.store_templates.data;

export const pageModuleSelectableCustomBrandedOrTemplateLooks = createSelector(
  [storeTemplatesSelector, availableStoresSelector],
  (storeTemplates, availableStores) => {
    const activeTemplates = storeTemplates.filter(
      l => !l.is_deleted && !l.is_draft && !l.is_private,
    );

    const usedTemplatesIds = new Set(
      availableStores.filter(l => l.from_store_template !== null).map(l => l.from_store_template),
    );
    const notUsedTemplates = activeTemplates.filter(l => !usedTemplatesIds.has(l.id));

    return [...availableStores, ...notUsedTemplates];
  },
);

export default entitySelectors;
